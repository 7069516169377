import React, {useRef, useEffect, useState, useContext, Fragment} from "react";
// import "./styles.css";

import {
    ChakraProvider,
    Box,
    Flex,
    Text,
    Image,
    VStack,
    HStack,
    Stack,
    Code,
    Grid,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    InputLeftAddon,
    Button,
    Icon,
    IconButton,
    theme,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Select,
    Skeleton,
    Progress,
    Spinner,
    Divider,
    Checkbox, 
    CheckboxGroup,
    Heading
} from '@chakra-ui/react';

import { MdMenu, MdHistory, MdCameraAlt, MdPerson, MdSearch } from "react-icons/md"

import "./tabulator-custom.css";

import 'react-tabulator/lib/styles.css'; // required styles
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css";
import { ReactTabulator, reactFormatter } from 'react-tabulator';

import axios from "axios";

import MainStoreContext from "../stores/MainStoreContext";

import { toJS } from "mobx";
import { observer } from "mobx-react";

import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

import {
  StatusText,
  RowActions,
  OtherActions,
  AlertPopup,
  ModalPopup,
  ImageLoader,
} from "../shared/CustomTabulator";

const SearchBox = observer(props => {

  const store = useContext(MainStoreContext);  

  const [initLoad, setInitLoad] = useState(false);

  const [searchParam, setSearchParam] = useState("any");
  const [prevSearchValue, setPrevSearchValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const searchNow = e => {
    if(searchParam === "name"){
        store._searchByName({query:searchValue})
      }else{
        store._searchAny({query:searchValue})
      }
      setPrevSearchValue(searchValue);
      setInitLoad(true);
  }

  useEffect(()=>{
    return ()=>{
        store._resetSearch();
    }
  },[])

  return (
        <Stack {...props}>
          <Stack isInline>
              <Select placeholder="" value={searchParam} onChange={e=>setSearchParam(e.target.value)} w={300}>
                {/* <option value="bin">Search By BIN</option> */}
                <option value="any">Search Any</option>
                <option value="name">Search By Name</option>
              </Select>
              <InputGroup size="md">
                <Input
                    pr="4.5rem"
                    placeholder=""
                    onChange={e=>setSearchValue(e.target.value)} value={searchValue}
                    onKeyDown={e=>{
                        if(e.key === 'Enter') searchNow()
                    }}
                />
                <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={()=>{
                        setSearchValue("")
                    }}>
                        Clear
                    </Button>
                </InputRightElement>
              </InputGroup>
              <Button w={200} colorScheme="blue" onClick={()=>{
                searchNow();
              }}>Search</Button>
              <Button w={200} colorScheme="red" onClick={()=>{
                setSearchValue("")
                store._resetSearch();
              }}>Reset</Button>
          </Stack>
          {store.searchQuery !== "" && store.loading && (
            <Text mt={4}>{`Found ${store.searchData.length} result${store.searchData.length == 1 ? '' : 's'} for "${prevSearchValue}".`}</Text>
          )}
        </Stack>
  )
})

export default observer(() => {

    const store = useContext(MainStoreContext);  

    const ref = useRef(null)

    const { isOpen, onOpen, onClose } = useDisclosure()

    //alert control
    const [isOpenVer, setOpenVer] = useState(false);
    const [isOpenAp, setOpenAp] = useState(false);

    //modal control
    const [isOpenView, setOpenView] = useState(false);
    const [isOpenHis, setOpenHis] = useState(false);
    const [isOpenProof, setOpenProof] = useState(false);

    const [selectedData, setSelectedData] = useState();
    
    const [date, setDate] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [progress, setProgress] = useState(0);

    const [history, setHistory] = useState([]);
    const [proofURL, setProofURL] = useState("");

    const [searchValue, setSearchValue] = useState("");
    const [filters, setFilters] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);

    const [columns, setColumns] = useState(
      [
            { 
              title: "Status", field: "status", frozen:true, width:160,
                mutator: (value, data, type, params, component) => {
                  return data.status_override ? data.actual_status : data.status;
                },
                formatter: reactFormatter(<StatusText />)
            },
            {
              title: "Actions",
              field: "custom",
              hozAlign: "left",
              frozen: true,
              width:120,
              headerSort:false,
              formatter: reactFormatter(
                <RowActions 
                  onVerify={data=>{
                    setSelectedData(data);
                    setOpenVer(true);
                  }}
                  onApprove={data=>{
                    setSelectedData(data);
                    setOpenAp(true);
                  }}
                />
              ),
              cellClick:(e, cell)=>{
                e.stopPropagation(); //prevent click event from reaching the row.
              },
            },
            {
              title: "",
              field: "custom",
              hozAlign: "left",
              frozen: true,
              width:160,
              headerSort:false,
              formatter: reactFormatter(
                <OtherActions
                  onView={data=>{
                    setSelectedData(data);
                    setOpenView(true);
                  }}
                  onShowHistory={data=>{
                    store._getHistory({tracking_id:data.tracking_id}).then(res=>{
                      if(res.data.length == 0) return;
                      setOpenHis(true);
                      setHistory(res.data);
                    })
                  }}
                  onShowProof={data=>{
                    store._getProof({id:data.id}).then(res=>{
                      setOpenProof(true);
                      setProofURL(res.data.url);
                    })
                  }}
                />
              ),
              cellClick:(e, cell)=>{
                e.stopPropagation(); //prevent click event from reaching the row.
              },
            }
      ]
    )

    useEffect(()=>{
      setColumns(store.columnFormat.concat(columns))
    }, [])

    const rowClick = (e, row) => {
        console.log("ref table: ", ref.current.table); // this is the Tabulator table instance
        console.log(`rowClick id: \${row.getData().id}`, row, e);
        // setSelectedName(row.getData().first_name)
    };

    const searchTable = (e) => {
      const table = ref.current.table;
      var filters = [];
      var columns = table.getColumns();

      if(searchValue === ""){
        table.clearFilter();
        return;
      }

      columns.forEach(function (column) {
          filters.push({
              field: column.getField(),
              type: "like",
              value: searchValue,
          });
      });

      table.setFilter([filters]);
    }

    const clearSearch = e => {
      ref.current.table.clearFilter();
    }

    return (
        <Stack m={4}>
            <Heading size="md">Search Records</Heading>
            <SearchBox />
            <Box textAlign="center">
              {store.searchData.length > 0 ? (
                 <>
                  <Progress size="sm" colorScheme="red" hasStripe value={store.searchProgress} />
                  <ReactTabulator
                    ref={ref}
                    columns={columns}
                    data={store.searchData}
                    // rowClick={rowClick}
                    dataFiltered={(filters, rows)=>{
                      setFilters(filters);
                      setFilteredRows(rows);
                    }}
                    options={{
                        layout:"fitDataTable",
                        // selectable:1,
                        // selectableRollingSelection:false, // disable rolling selection
                        pagination:"local",
                        paginationSize:10,
                        paginationSizeSelector:[5, 10, 50],
                        movableColumns:true,
                    }}
                    data-custom-attr="test-custom-attribute"
                    className="tabulator-custom"
                />
                </>
              ):(
                <>
                  <Progress size="sm" colorScheme="red" hasStripe value={store.searchProgress} />
                  {/* <Skeleton height={550} /> */}
                </>
              )}
            </Box>
            <AlertPopup 
              selectedData={selectedData} 
              isOpen={isOpenVer} 
              onClose={()=>setOpenVer(false)} 
              onAction={()=>{
                store._updateStatus({id:selectedData.id, status:"BPLO Processing"}).then(res=>{
                  console.log(res)
                  setSelectedData(null)
                  setOpenVer(false)
                })
              }}
              title={`Verify ${selectedData?selectedData.tracking_id:"Undefined"}?`}
              actionTitle="Verify"
            >Are you sure? You can't undo this action afterwards.</AlertPopup>
            <AlertPopup 
              selectedData={selectedData} 
              isOpen={isOpenAp} 
              onClose={()=>setOpenAp(false)} 
              onAction={()=>{
                store._updateStatus({id:selectedData.id, status:"For Delivery"}).then(res=>{
                  console.log(res)
                  setSelectedData(null)
                  setOpenAp(false)
                })
              }}
              title={`Approve ${selectedData?selectedData.tracking_id:"Undefined"} "For Delivery?"`}
              actionTitle="Approve"
              actionColor="red"
            >Are you sure? You can't undo this action afterwards.</AlertPopup>
            <ModalPopup 
              selectedData={selectedData} 
              isOpen={isOpenView} 
              onClose={()=>setOpenView(false)} 
              onAction={()=>{

              }}
              title={"View Entry"}
              actionTitle="Approve"
              actionColor="red"
            >
              <Stack>
                {Object.entries(selectedData??{}).filter(data=>{
                  const exclude = ["updated_at", "status_debug", "notes", "custom"];
                  let include = true;
                  exclude.map(e=>{
                    if(data[0] === e) include = false;
                  })
                  return include;
                }).map((data, i)=>{
                  const property = (data[0].charAt(0).toUpperCase() + data[0].substr(1)).replace(/_/g, ' ');
                  return (
                    <Fragment key={`${i}`}>
                      <Divider />
                      <Stack isInline>
                        <Text>{`${property}: `}</Text>
                        <Text fontWeight={500}>{`${data[1]??"N/A"}`}</Text>
                      </Stack>
                   </Fragment>
                  )
                })}
              </Stack>
            </ModalPopup>
            <ModalPopup 
              data={history} 
              isOpen={isOpenHis} 
              onClose={()=>setOpenHis(false)} 
              onAction={()=>{

              }}
              title={"View History"}
              actionTitle="Approve"
              actionColor="red"
            >
              <Stack>
                {history.map((data,i)=>{
                  return (
                    <Fragment key={`${i}`}>
                      <Divider />
                      <Stack isInline spacing={2}>
                        <Text w={190}>{`${moment(data.created_at).local().format('YYYY-DD-MM LTS')}`}</Text>
                        <Text fontWeight={500}>{data.status}</Text>
                        <Text>{`by ${data.user.username}`}</Text>
                      </Stack>
                   </Fragment>
                  )
                })}
              </Stack>
            </ModalPopup>
            <ModalPopup 
              data={proofURL} 
              isOpen={isOpenProof} 
              onClose={()=>setOpenProof(false)} 
              onAction={()=>{

              }}
              title={"View Proof"}
              actionTitle="Approve"
              actionColor="red"
            >
              <ImageLoader src={proofURL}  />              
            </ModalPopup>
        </Stack>
    )
})

