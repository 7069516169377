import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';
// import "./styles.css";

import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Image,
  VStack,
  HStack,
  Stack,
  Code,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  Button,
  Icon,
  IconButton,
  theme,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Select,
  Skeleton,
  Progress,
  Spinner,
  Divider,
  Checkbox,
  CheckboxGroup,
  Heading,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import {
  MdMenu,
  MdHistory,
  MdCameraAlt,
  MdPerson,
  MdSearch,
} from 'react-icons/md';

import './tabulator-custom.css';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator, reactFormatter } from 'react-tabulator';

import axios from 'axios';

import MainStoreContext from '../stores/MainStoreContext';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import {
  StatusText,
  RowActions,
  OtherActions,
  AlertPopup,
  ModalPopup,
  ImageLoader,
} from '../shared/CustomTabulator';

export default observer(() => {
  const store = useContext(MainStoreContext);

  const ref = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  //alert control
  const [isOpenVer, setOpenVer] = useState(false);
  const [isOpenAp, setOpenAp] = useState(false);

  //modal control
  const [isOpenView, setOpenView] = useState(false);
  const [isOpenHis, setOpenHis] = useState(false);
  const [isOpenProof, setOpenProof] = useState(false);

  const [selectedData, setSelectedData] = useState();

  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState(new Date(store.startDate));
  const [endDate, setEndDate] = useState(new Date(store.endDate));

  const [progress, setProgress] = useState(0);

  const [history, setHistory] = useState([]);
  const [proofURL, setProofURL] = useState('');

  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [columns, setColumns] = useState([
    {
      title: 'Status',
      field: 'status',
      frozen: true,
      width: 160,
      mutator: (value, data, type, params, component) => {
        return data.status_override ? data.actual_status : data.status;
      },
      formatter: reactFormatter(<StatusText />),
    },
    {
      title: 'Actions',
      field: 'custom',
      hozAlign: 'left',
      frozen: true,
      width: 120,
      headerSort: false,
      formatter: reactFormatter(
        <RowActions
          onVerify={data => {
            setSelectedData(data);
            setOpenVer(true);
          }}
          onApprove={data => {
            setSelectedData(data);
            setOpenAp(true);
          }}
        />
      ),
      cellClick: (e, cell) => {
        e.stopPropagation(); //prevent click event from reaching the row.
      },
    },
    {
      title: '',
      field: 'custom',
      hozAlign: 'left',
      frozen: true,
      width: 160,
      headerSort: false,
      formatter: reactFormatter(
        <OtherActions
          onView={data => {
            setSelectedData(data);
            setOpenView(true);
          }}
          onShowHistory={data => {
            store._getHistory({ tracking_id: data.tracking_id }).then(res => {
              if (res.data.length == 0) return;
              setOpenHis(true);
              setHistory(res.data);
            });
          }}
          onShowProof={data => {
            store._getProof({ id: data.id }).then(res => {
              setOpenProof(true);
              setProofURL(res.data.url);
            });
          }}
        />
      ),
      cellClick: (e, cell) => {
        e.stopPropagation(); //prevent click event from reaching the row.
      },
    },
  ]);

  useEffect(() => {
    setColumns(store.columnFormat.concat(columns));
    if (ref.current) ref.current.table.setSort('id', 'desc');
    store._getRealTimeData({ setProgress: setProgress });
    // store._refreshStatuses();
  }, []);

  const rowClick = (e, row) => {
    console.log('ref table: ', ref.current.table); // this is the Tabulator table instance
    console.log(`rowClick id: \${row.getData().id}`, row, e);
    // setSelectedName(row.getData().first_name)
  };

  const searchTable = e => {
    const table = ref.current.table;
    var filters = [];
    var columns = table.getColumns();

    if (searchValue === '') {
      table.clearFilter();
      return;
    }

    columns.forEach(function (column) {
      filters.push({
        field: column.getField(),
        type: 'like',
        value: searchValue,
      });
    });

    table.setFilter([filters]);
  };

  const clearSearch = e => {
    ref.current.table.clearFilter();
  };

  return (
    <Stack m={4}>
      <HStack>
        <Heading size="md" w="180px">
          Real-time Data
        </Heading>
      </HStack>

      <Stack
        spacing={4}
        pointerEvents={!store.loading ? 'auto' : 'none'}
        opacity={!store.loading > 0 ? 1 : 0.25}
      >
        {/* <SimpleGrid columns={1} spacing={4}> */}
        <HStack>
          <Flatpickr
            value={startDate}
            options={{
              allowInput: true,
              dateFormat: 'm/d/Y',
              disable: [
                function (date) {
                  return date > new Date(moment().format('YYYY-MM-DD'));
                },
              ],
            }}
            onChange={d => {
              setStartDate(d[0]);
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return (
                <InputGroup>
                  <InputLeftAddon
                    backgroundColor="rgba(161, 27, 31, 0.8)"
                    color="#fff"
                    w={110}
                    children="Start Date"
                  />
                  <Input
                    isRequired
                    placeholder="Set Start Date"
                    {...props}
                    ref={ref}
                  />
                </InputGroup>
              );
            }}
          />
          <Flatpickr
            value={endDate}
            options={{
              allowInput: true,
              dateFormat: 'm/d/Y',
              disable: [
                function (date) {
                  return date > new Date(moment().format('YYYY-MM-DD'));
                },
              ],
            }}
            onChange={d => {
              setEndDate(d[0]);
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return (
                <InputGroup>
                  <InputLeftAddon
                    backgroundColor="rgba(161, 27, 31, 0.8)"
                    color="#fff"
                    w={110}
                    children="End Date"
                  />
                  <Input
                    isRequired
                    placeholder="Set End Date"
                    {...props}
                    ref={ref}
                  />
                </InputGroup>
              );
            }}
          />
          <HStack w="100%">
            <Button
              w="100%"
              colorScheme="red"
              onClick={() => {
                store._getRealTimeData({
                  startDate,
                  endDate,
                });
              }}
            >
              Update Filters
            </Button>
            <Button
              w="100%"
              colorScheme="green"
              onClick={() => {
                store._refreshStatuses();
              }}
            >
              Refresh Statuses
            </Button>
          </HStack>
        </HStack>
        {/* </SimpleGrid> */}
        <CheckboxGroup
          mt={4}
          colorScheme="red"
          defaultValue={store.statusFilters}
          onChange={statuses => store._updateStatusFilters(statuses)}
        >
          <HStack>
            <Checkbox value="For Pickup">For Pickup</Checkbox>
            <Checkbox value="In Transit to BPLO">
              In Transit to City Hall
            </Checkbox>
            <Checkbox value="Under Verification">Under Verification</Checkbox>
            <Checkbox value="BPLO Processing">Processing</Checkbox>
            <Checkbox value="For Delivery">For Delivery</Checkbox>
            <Checkbox value="In Transit to Client">
              In Transit to Client
            </Checkbox>
            <Checkbox value="Delivered">Delivered</Checkbox>
          </HStack>
        </CheckboxGroup>
        <HStack>
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              children={<MdSearch color="gray.300" />}
            />
            <Input
              pr="4.5rem"
              placeholder="Search Anything"
              value={searchValue}
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') searchTable();
              }}
            />
            <InputRightElement width="4.5rem">
              <Button
                h="1.75rem"
                size="sm"
                onClick={() => {
                  setSearchValue('');
                  clearSearch();
                }}
              >
                Clear
              </Button>
            </InputRightElement>
          </InputGroup>
          <Button w={150} colorScheme="blue" onClick={searchTable}>
            Search
          </Button>
        </HStack>
        {filters.length <= 0 && searchValue === '' && (
          <Text as="i">
            {`Found ${store.realtimeData.length} entries ${
              startDate.toDateString() === endDate.toDateString()
                ? `for ${moment(startDate).format('ll')}`
                : `between ${moment(startDate).format('ll')} and ${moment(
                    endDate
                  ).format('ll')}.`
            }`}
          </Text>
        )}

        {filteredRows.length != store.realtimeData.length &&
          store.realtimeData.length > 0 && (
            <Text as="i">{`Found ${filteredRows.length} out of ${store.realtimeData.length} entries for "${searchValue}" in currently loaded data.`}</Text>
          )}
      </Stack>
      <Box textAlign="center">
        {store.realtimeData.length > 0 ? (
          <>
            <Progress
              size="sm"
              colorScheme="red"
              hasStripe
              value={store.realTimeProgress}
            />
            <ReactTabulator
              initialSort={[
                { column: 'id', dir: 'desc' }, //sort by this first
              ]}
              ref={ref}
              columns={columns}
              // data={store.searchData.length > 0 || store.searchQuery !== "" ? store.searchData : store.realtimeData}
              data={store.realtimeData}
              // rowClick={rowClick}
              dataFiltered={(filters, rows) => {
                setFilters(filters);
                setFilteredRows(rows);
              }}
              options={{
                layout: 'fitDataTable',
                // selectable:1,
                // selectableRollingSelection:false, // disable rolling selection
                pagination: 'local',
                paginationSize: 10,
                paginationSizeSelector: [5, 10, 50],
                movableColumns: true,
              }}
              data-custom-attr="test-custom-attribute"
              className="tabulator-custom"
            />
          </>
        ) : (
          <>
            <Progress size="sm" colorScheme="red" hasStripe value={progress} />
            <Skeleton height={550} />
          </>
        )}
      </Box>
      <AlertPopup
        selectedData={selectedData}
        isOpen={isOpenVer}
        onClose={() => setOpenVer(false)}
        onAction={() => {
          store
            ._updateStatus({ id: selectedData.id, status: 'BPLO Processing' })
            .then(res => {
              // console.log(res)
              setSelectedData(null);
              setOpenVer(false);
            });
        }}
        title={`Verify Entry:${selectedData ? selectedData.id : 'Undefined'}?`}
        actionTitle="Verify"
      >
        Are you sure? You can't undo this action afterwards.
      </AlertPopup>
      <AlertPopup
        selectedData={selectedData}
        isOpen={isOpenAp}
        onClose={() => setOpenAp(false)}
        onAction={() => {
          store
            ._updateStatus({ id: selectedData.id, status: 'For Delivery' })
            .then(res => {
              // console.log(res)
              setSelectedData(null);
              setOpenAp(false);
            });
        }}
        title={`Approve Entry:${
          selectedData ? selectedData.id : 'Undefined'
        } "For Delivery?"`}
        actionTitle="Approve"
        actionColor="red"
      >
        Are you sure? You can't undo this action afterwards.
      </AlertPopup>
      <ModalPopup
        selectedData={selectedData}
        isOpen={isOpenView}
        onClose={() => setOpenView(false)}
        onAction={() => {}}
        title={'View Entry'}
        actionTitle="Approve"
        actionColor="red"
      >
        <Stack>
          {Object.entries(selectedData ?? {})
            .filter(data => {
              const exclude = ['updated_at', 'status_debug', 'notes', 'custom'];
              let include = true;
              exclude.map(e => {
                if (data[0] === e) include = false;
              });
              return include;
            })
            .map((data, i) => {
              const property = (
                data[0].charAt(0).toUpperCase() + data[0].substr(1)
              ).replace(/_/g, ' ');
              return (
                <Fragment key={`${i}`}>
                  <Divider />
                  <Stack isInline>
                    <Text>{`${property}: `}</Text>
                    <Text fontWeight={500}>{`${data[1] ?? 'N/A'}`}</Text>
                  </Stack>
                </Fragment>
              );
            })}
        </Stack>
      </ModalPopup>
      <ModalPopup
        data={history}
        isOpen={isOpenHis}
        onClose={() => setOpenHis(false)}
        onAction={() => {}}
        title={'View History'}
        actionTitle="Approve"
        actionColor="red"
      >
        <Stack>
          {history.map((data, i) => {
            return (
              <Fragment key={`${i}`}>
                <Divider />
                <Stack isInline spacing={2}>
                  <Text w={190}>{`${moment(data.created_at)
                    .local()
                    .format('YYYY-DD-MM LTS')}`}</Text>
                  <Text fontWeight={500}>{data.status}</Text>
                  <Text>{`by ${data.user.username}`}</Text>
                </Stack>
              </Fragment>
            );
          })}
        </Stack>
      </ModalPopup>
      <ModalPopup
        data={proofURL}
        isOpen={isOpenProof}
        onClose={() => setOpenProof(false)}
        onAction={() => {}}
        title={'View Proof'}
        actionTitle="Approve"
        actionColor="red"
      >
        <ImageLoader src={proofURL} />
      </ModalPopup>
    </Stack>
  );
});
