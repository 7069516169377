import React, {
  useRef,
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  HStack,
  VStack,
  Stack,
  Code,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Icon,
  IconButton,
  theme,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  useToast,
  Image,
} from '@chakra-ui/react';

import { MdMenu, MdPerson, MdLock } from 'react-icons/md';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import Header from './shared/Header';
import Dashboard from './Dashboard/Dashboard';
import SearchRecords from './Dashboard/SearchRecords';
import DownloadCSV from './Dashboard/DownloadCSV';

import MainStoreContext from './stores/MainStoreContext';
import { observer } from 'mobx-react';

const GlobalToast = observer(props => {
  const store = useContext(MainStoreContext);
  const toast = useToast();

  useEffect(() => {
    // toast({
    //   title: "An error occurred.",
    //   description: "Unable to create user account.",
    //   status: "error",
    //   duration: 9000,
    //   isClosable: true,
    // })
    toast(store.toastData);
  });

  return <></>;
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      {/* <Main /> */}
      <>
        <AuthExample />
        {/* <GlobalToast /> */}
      </>
    </ChakraProvider>
  );
}

function Main() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <>
      <Header
        leftIcon={
          <Button colorScheme="transparent" onClick={onOpen}>
            <Icon as={MdMenu} />
          </Button>
          // <Button bg="transparent" border="1px">
          //   Create account
          // </Button>
        }
        rightIcon={
          <AuthButton />
          // <Button bg="transparent" border="1px">
          //   Logout
          // </Button>
        }
        menuItems={
          // <>
          //   <Button mr={6} colorScheme="red">Item 1</Button>
          //   <Button mr={6} colorScheme="red">Item 2</Button>
          //   <Button mr={6} colorScheme="red">Item 3</Button>
          // </>
          <>
            <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
              {'Item 1'}
            </Text>
            <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
              {'Item 2'}
            </Text>
          </>
        }
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create your account</DrawerHeader>

            <DrawerBody>
              <Input placeholder="Type here..." />
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button color="blue">Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

function AuthExample() {
  return (
    // <ProvideAuth>
    <Router>
      <div>
        {/* <AuthButton /> */}
        <Switch>
          {/* <Route path="/public">
              <PublicPage />
            </Route> */}
          <Route path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute path="/">
            <MainScreen />
          </PrivateRoute>
        </Switch>
      </div>
    </Router>
    // </ProvideAuth>
  );
}

const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

/** For more details on
 * `authContext`, `ProvideAuth`, `useAuth` and `useProvideAuth`
 * refer to: https://usehooks.com/useAuth/
 */
const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = cb => {
    return fakeAuth.signin(() => {
      setUser('user');
      cb();
    });
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout,
  };
}

function AuthButton() {
  const store = useContext(MainStoreContext);

  let history = useHistory();
  let auth = useAuth();

  return store.userData ? (
    <Stack isInline alignItems="center">
      <Text>{`Hello, ${store.userData.user.username}`}</Text>
      <Button
        bg="transparent"
        border="1px"
        onClick={() => {
          // auth.signout(() => history.push("/"));
          store._logout(() => {
            console.log('logging out?');
            history.go(0);
          });
        }}
      >
        Logout
      </Button>
    </Stack>
  ) : (
    <p>You are not logged in.</p>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
  const store = useContext(MainStoreContext);

  // let auth = useAuth();

  useEffect(() => {
    // console.log("AAAH", store.userData)
  });

  return (
    <Route
      {...rest}
      render={({ location }) =>
        // auth.user ? (
        store.userData ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

const LoginPage = () => {
  const store = useContext(MainStoreContext);

  let history = useHistory();
  let location = useLocation();
  // let auth = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  let { from } = location.state || { from: { pathname: '/' } };

  const [loading, setLoading] = useState(false);

  let login = () => {
    setLoading(true);
    store
      ._login({
        // identifier:"keriadmin",
        // password:"kerikita"
        identifier: username,
        password: password,
      })
      .then(() => {
        setLoading(false);
        history.replace(from);
      });
    // auth.signin(() => {
    //   history.replace(from);
    // });
  };

  useEffect(() => {
    // console.log('???', store.userData);
    if (store.userData) {
      history.replace(from);
    }
    // auth.signin(() => {
    // history.replace(from);
    // });
    // if(store.userData){
    //   history.replace(from);
    // }
  }, []);

  return (
    <Box
      height="100vh"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Keri.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Box height="100%" backgroundColor="rgba(255,255,255,0.25)" pt="40px">
        <Box>
          <Stack
            mt={50}
            maxW={480}
            margin="0 auto"
            textAlign="center"
            p={4}
            backgroundColor="#fff"
            boxShadow="2xl"
            p="6"
            rounded="md"
            spacing={3}
          >
            <Image
              boxSize="140px"
              objectFit="contain"
              src={`${process.env.PUBLIC_URL}/keri-logo.png`}
              margin="0 auto"
            />
            <Text>Welcome to Keri City</Text>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdPerson color="gray.100" />}
              />
              <Input
                placeholder="Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<MdLock color="gray.100" />}
              />
              <Input
                placeholder="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </InputGroup>
            <Button isLoading={loading} colorScheme="red" onClick={login}>
              Login
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
    // <>
    //    <Image
    //       pos="absolute"
    //       top={0}
    //       left={0}
    //       width={"100%"}
    //       // height={"100%"}
    //       objectFit="cover"
    //       src={`${process.env.PUBLIC_URL}/Keri.jpg`}
    //     />
    //   <Box margin={"0 auto"}>

    //     <Stack m={4} spacing={0} textAlign="center">
    //       <Text>Welcome to Keri City</Text>
    //       <Input placeholder="Username" value={username} onChange={e=>setUsername(e.target.value)} />
    //       <Input placeholder="Password" type="password" value={password} onChange={e=>setPassword(e.target.value)} />
    //       <Button colorScheme="red" onClick={login}>Login</Button>
    //       {/* <Text>You must log in to view the page at {from.pathname}</Text> */}
    //     </Stack>
    //   </Box>
    // </>
  );
};

function MainScreen() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  return (
    <Router>
      <Header
        lefticon={
          <Button colorScheme="transparent" onClick={onOpen}>
            {/* <Icon as={MdMenu} /> */}
            Menu
          </Button>
        }
        righticon={
          <AuthButton />
          // <Button bg="transparent" border="1px">
          //   Logout
          // </Button>
        }
        // menuitems={
        // <>
        //   <Button mr={6} colorScheme="red">Item 1</Button>
        //   <Button mr={6} colorScheme="red">Item 2</Button>
        //   <Button mr={6} colorScheme="red">Item 3</Button>
        // </>
        // <>
        //   <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
        //     {"Button 1"}
        //   </Text>
        //   <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
        //     {"Button 2"}
        //   </Text>
        // </>
        // }
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>

            <DrawerBody>
              <Stack>
                <Link to={`/`} onClick={onClose}>
                  Realtime Data
                </Link>
                <Link to={`/search-records`} onClick={onClose}>
                  Search Records
                </Link>
                <Link to={`/download-csv`} onClick={onClose}>
                  Download CSV
                </Link>
              </Stack>
            </DrawerBody>

            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/search-records">
          <SearchRecords />
        </Route>
        <Route path="/download-csv">
          <DownloadCSV />
        </Route>
        <Route>
          <Text>404 NOT FOUND</Text>
        </Route>
      </Switch>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function Topics() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  return (
    <div>
      <h2>Topics</h2>
      <ul>
        <li>
          <Link to={`${url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>

      <Switch>
        <Route exact path={path}>
          <h3>Please select a topic.</h3>
        </Route>
        <Route path={`${path}/:topicId`}>
          <Topic />
        </Route>
      </Switch>
    </div>
  );
}

function Topic() {
  // The <Route> that rendered this component has a
  // path of `/topics/:topicId`. The `:topicId` portion
  // of the URL indicates a placeholder that we can
  // get from `useParams()`.
  let { topicId } = useParams();

  return (
    <div>
      <h3>{topicId}</h3>
    </div>
  );
}

export default App;
