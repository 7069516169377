import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  Button,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';

import MainStoreContext from '../stores/MainStoreContext';
import { observer } from 'mobx-react';

// Note: This code could be better, so I'd recommend you to understand how I solved and you could write yours better :)
const Header = observer(props => {
  const store = useContext(MainStoreContext);

  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="#a11b1f"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        {props.lefticon}
      </Flex>

      <Box display={{ base: 'block', md: 'none' }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ sm: show ? 'block' : 'none', md: 'flex' }}
        width={{ sm: 'full', md: 'auto' }}
        alignItems="center"
        flexGrow={1}
      >
        <FormControl display="flex" alignItems="center">
          <FormLabel htmlFor="refresh-alert" mb="0">
            Auto Refresh
          </FormLabel>
          <Switch
            size="lg"
            id="refresh-alert"
            value={store.autoRefresh}
            onChange={e => store._toggleAutoRefresh(e.target.checked)}
          />
        </FormControl>
        {props.menuitems}
      </Box>

      <Box
        display={{ sm: show ? 'block' : 'none', md: 'block' }}
        mt={{ base: 4, md: 0 }}
      >
        {props.righticon}
      </Box>
    </Flex>
  );
});

export default Header;
