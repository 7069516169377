import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';
// import "./styles.css";

import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Image,
  VStack,
  HStack,
  Stack,
  Code,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  Button,
  Icon,
  IconButton,
  theme,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Select,
  Skeleton,
  Progress,
  Spinner,
  Divider,
  Checkbox,
  CheckboxGroup,
  Heading,
} from '@chakra-ui/react';

import {
  MdMenu,
  MdHistory,
  MdCameraAlt,
  MdPerson,
  MdSearch,
} from 'react-icons/md';

import './tabulator-custom.css';

import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css';
import { ReactTabulator, reactFormatter } from 'react-tabulator';

import axios from 'axios';

import MainStoreContext from '../stores/MainStoreContext';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import 'flatpickr/dist/themes/airbnb.css';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';

import {
  StatusText,
  RowActions,
  OtherActions,
  AlertPopup,
  ModalPopup,
  ImageLoader,
} from '../shared/CustomTabulator';

export default observer(() => {
  const store = useContext(MainStoreContext);

  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState(new Date(store.startDate));
  const [endDate, setEndDate] = useState(new Date(store.endDate));

  const [cd, setCD] = useState(false);

  return (
    <Stack m={4}>
      <Heading size="md">DOWNLOAD CSV</Heading>
      <Stack
        spacing={4}
        //   pointerEvents={!store.loading ? "auto" : "none"}
        //   opacity={!store.loading > 0 ? 1 : 0.25}
      >
        {/* <SimpleGrid columns={1} spacing={4}> */}
        <HStack>
          <Flatpickr
            value={startDate}
            options={{
              allowInput: true,
              dateFormat: 'm/d/Y',
              disable: [
                function (date) {
                  return date > new Date(moment().format('YYYY-MM-DD'));
                },
              ],
            }}
            onChange={d => {
              setStartDate(d[0]);
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return (
                <InputGroup>
                  <InputLeftAddon
                    backgroundColor="rgba(161, 27, 31, 0.8)"
                    color="#fff"
                    w={110}
                    children="Start Date"
                  />
                  <Input
                    isRequired
                    placeholder="Set Start Date"
                    {...props}
                    ref={ref}
                  />
                </InputGroup>
              );
            }}
          />
          <Flatpickr
            value={endDate}
            options={{
              allowInput: true,
              dateFormat: 'm/d/Y',
              disable: [
                function (date) {
                  return date > new Date(moment().format('YYYY-MM-DD'));
                },
              ],
            }}
            onChange={d => {
              setEndDate(d[0]);
            }}
            render={({ defaultValue, value, ...props }, ref) => {
              return (
                <InputGroup>
                  <InputLeftAddon
                    backgroundColor="rgba(161, 27, 31, 0.8)"
                    color="#fff"
                    w={110}
                    children="End Date"
                  />
                  <Input
                    isRequired
                    placeholder="Set End Date"
                    {...props}
                    ref={ref}
                  />
                </InputGroup>
              );
            }}
          />
          <HStack w="100%">
            <Button
              isLoading={cd}
              w="100%"
              colorScheme="red"
              onClick={() => {
                setCD(true);
                store
                  ._downloadCSV({
                    startDate,
                    endDate,
                  })
                  .then(() => {
                    setCD(false);
                  });
              }}
            >
              Download CSV
            </Button>
          </HStack>
        </HStack>
      </Stack>
    </Stack>
  );
});
