import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';

import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Image,
  VStack,
  HStack,
  Stack,
  Code,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputLeftAddon,
  Button,
  Icon,
  IconButton,
  theme,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Select,
  Skeleton,
  Progress,
  Spinner,
  Divider,
  Checkbox,
  CheckboxGroup,
  Heading,
} from '@chakra-ui/react';

import {
  MdMenu,
  MdHistory,
  MdCameraAlt,
  MdPerson,
  MdSearch,
} from 'react-icons/md';

import MainStoreContext from '../stores/MainStoreContext';

import { toJS } from 'mobx';

export const ImageLoader = props => {
  const { src } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <Skeleton {...props} isLoaded={loaded}>
      {!loaded && <Spinner size="xl" />}
      <Image
        src={src}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </Skeleton>
  );
};

export const ModalPopup = props => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.isOpen) setLoading(false);
  });

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>{props.footer}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const AlertPopup = props => {
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.isOpen) setLoading(false);
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={props.onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.title}
          </AlertDialogHeader>

          <AlertDialogBody>{props.children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button isLoading={loading} ref={cancelRef} onClick={props.onClose}>
              Cancel
            </Button>
            <Button
              isLoading={loading}
              colorScheme={props.actionColor ?? 'blue'}
              onClick={() => {
                setLoading(true);
                props.onAction();
              }}
              ml={3}
            >
              {props.actionTitle}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export const StatusText = props => {
  const statusOverride = props.cell._cell.row.data.status_override;
  let cellValue = props.cell._cell.value;
  const cellValueLowerCase = cellValue ? cellValue.toLowerCase() : '?';
  let textColor = '#000';

  if (cellValueLowerCase.indexOf('verification') >= 0) textColor = '#ff0000';
  if (cellValueLowerCase.indexOf('processing') >= 0) textColor = '#0000ff';
  if (cellValueLowerCase.indexOf('delivery') >= 0) textColor = 'orange';
  if (cellValueLowerCase.indexOf('delivered') >= 0) textColor = '#006400';

  if (cellValueLowerCase.indexOf('processing') >= 0) {
    cellValue = 'Processing';
  }
  if (cellValueLowerCase.indexOf('to bplo') >= 0) {
    cellValue = 'In Transit to City Hall';
  }

  const capFirst = cellValue
    ? cellValue.charAt(0).toUpperCase() + cellValue.substr(1)
    : '?';

  return (
    <Text
      fontWeight={500}
      color={textColor}
      textDecor={statusOverride ? 'underline' : 'auto'}
    >
      {capFirst}
    </Text>
  );
};

export const CustomColumn = props => {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value || 'Edit | Show';

  return (
    <Box pos="absolute" top={4} left={10}>
      <Button
        onClick={() => console.log(toJS(rowData))}
        backgroundColor="red"
        borderRadius={4}
        px={12}
        py={8}
        color="#fff"
        mr={4}
      >
        Action
      </Button>
      <Button
        onClick={() => console.log(props)}
        backgroundColor="blue"
        borderRadius={4}
        px={8}
        py={8}
        color="#fff"
        mr={4}
      >
        <MdMenu size={20} />
      </Button>
      <Button
        onClick={() => props.onClick(rowData.first_name)}
        backgroundColor="green"
        borderRadius={4}
        px={8}
        py={8}
        color="#fff"
        mr={4}
      >
        <MdMenu size={20} />
      </Button>
    </Box>
  );
};

export const CellButton = props => {
  return (
    <Box
      as="button"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      px={8}
      py={6}
      mx={4}
      borderRadius={4}
      // borderWidth={2}
      // borderColor="#999"
      color="#fff"
      fontWeight={500}
      bg="#f5f6f7"
      _hover={{ opacity: 0.75 }}
      _active={{
        bg: '#dddfe2',
        transform: 'scale(0.98)',
        borderColor: '#bec3c9',
      }}
      _focus={{
        boxShadow:
          '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
};

export const RowActions = props => {
  const store = useContext(MainStoreContext);

  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value;

  return (
    <Flex
      pos="absolute"
      top={0}
      left={0}
      h={'100%'}
      alignItems="center"
      justifyContent="center"
    >
      {rowData.status &&
        rowData.status.toLowerCase().indexOf('verification') >= 0 && (
          <CellButton
            backgroundColor={'blue'}
            width={100}
            onClick={() => {
              if (props.onVerify) props.onVerify(rowData);
            }}
          >
            {'VERIFY'}
          </CellButton>
        )}
      {rowData.status &&
        rowData.status.toLowerCase().indexOf('processing') >= 0 && (
          <CellButton
            backgroundColor={'red'}
            width={100}
            onClick={() => {
              if (props.onApprove) props.onApprove(rowData);
            }}
          >
            {'APPROVE'}
          </CellButton>
        )}
    </Flex>
  );
};

export const OtherActions = props => {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value;

  useEffect(() => {}, []);

  return (
    <Flex
      pos="absolute"
      top={0}
      left={0}
      h={'100%'}
      alignItems="center"
      justifyContent="center"
    >
      <CellButton
        borderColor="lightgrey"
        borderWidth={1}
        onClick={() => {
          props.onView(rowData);
        }}
      >
        <MdPerson color="#a11b1f" size={20} />
      </CellButton>
      <CellButton
        borderColor="lightgrey"
        borderWidth={1}
        onClick={() => {
          props.onShowHistory(rowData);
        }}
      >
        <MdHistory color="#a11b1f" size={20} />
      </CellButton>
      <CellButton
        borderColor="lightgrey"
        borderWidth={1}
        onClick={() => {
          props.onShowProof(rowData);
        }}
      >
        <MdCameraAlt color="#a11b1f" size={20} />
      </CellButton>
    </Flex>
  );
};
