import { createContext } from 'react';
import { runInAction, makeAutoObservable } from 'mobx';

import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import io from 'socket.io-client';

import { createStandaloneToast } from '@chakra-ui/react';

import fileDownload from 'js-file-download';

global.moment = moment;

const pageLimit = 500;

const fetchData = async ({ url, total_url, token, onProgress, limit }) => {
  let allData = [];
  let morePagesAvailable = true;
  let currentPage = 0;
  limit = limit ? limit : 100;

  let total;
  let progress = 0;

  if (total_url) {
    // console.log("TOTAL URL", total_url)
    const { data } = await axios({
      method: 'get',
      url: total_url,
      headers: {
        Authorization: token,
      },
    });
    total = data;
  }

  console.log('TOTAL', total);

  while (morePagesAvailable) {
    try {
      const tempurl = `${url}&_start=${currentPage}&_limit=${limit}`;
      // console.log(tempurl)
      let { data } = await axios({
        method: 'get',
        url: tempurl,
        headers: {
          Authorization: token,
        },
      });
      // console.log(data)
      allData = allData.concat(data);
      morePagesAvailable = data.length;
      if (total) {
        if (total < limit) {
          progress = 1;
        } else {
          progress = currentPage / total;
          if (progress >= 1) progress = 1;
        }
      }
      if (onProgress) onProgress(progress);
      currentPage += limit;
    } catch (err) {
      morePagesAvailable = false;
      console.log(err);
      return null;
    }
  }

  return allData;
};

export class MainStoreContext {
  // baseURL = 'http://localhost:1337';
  baseURL = 'https://bplo-admin.keridelivery.com';

  variable = '';

  autoRefresh = false;

  userData;

  isAuthenticated = false;

  realtimeData = [];
  loading = false;
  startDate = moment().format('YYYY-MM-DD');
  endDate = moment().format('YYYY-MM-DD');
  // endDate = moment().add(1, "day").format("YYYY-MM-DD");

  searchData = [];
  searchQuery = '';
  searchType = 'any';

  toastData = {};

  realTimeProgress = 0;
  searchProgress = 0;
  // statusFilters = [
  //     "For Pickup",
  //     "Under Verification",
  //     "BPLO Processing",
  //     "For Delivery",
  //     "Delivered"
  // ]

  statusFilters = ['Under Verification', 'BPLO Processing', 'For Delivery'];

  columnFormat = [
    { title: 'Department', field: 'department' },
    { title: 'ID', field: 'id' },
    {
      title: 'Tracking ID',
      field: 'tracking_id',
      cellClick: (e, cell) => {
        e.stopPropagation(); //prevent click event from reaching the row.
        const value = cell._cell.value;
        navigator.clipboard.writeText(value);
        this._globalToast({
          title: 'Copied to clipboard!',
          description: value,
          status: 'success',
        });
      },
    },
    {
      title: 'Entry Date',
      field: 'created_at',
      sorter: 'datetime',
      sorterParams: { format: 'YYYY-DD-MM LTS' },
      mutator: (value, data, type, params, component) => {
        // const date = value._cell.value
        // return moment(date).local().format('YYYY-DD-MM LTS');
        // return moment(data.created_at).local().format('YYYY-DD-MM LTS');
        return moment(data.created_at).local().format('MM-DD-YYYY LTS');
        // return moment(data.created_at).local().format('MMMM Do YYYY, h:mm:ss a');
      },
    },
    { title: 'External ID', field: 'external_reference_id' },
    { title: 'BIN', field: 'bin', cellClick: (e, cell) => e.stopPropagation() },
    { title: 'Business Name', field: 'business_name' },
    {
      title: 'Name',
      field: 'first_name',
      mutator: (value, data, type, params, component) => {
        return data.first_name + ' ' + data.last_name;
      },
    },
    { title: 'Email', field: 'email' },
    { title: 'Contact #', field: 'mobile_number' },
    { title: 'Address', field: 'address' },
    { title: 'Barangay', field: 'barangay' },
    { title: 'Service', field: 'service' },
    { title: 'Payment Method', field: 'payment_method' },
    { title: 'Reference Number', field: 'reference_number' },
    // { title: "Notes", field: "notes" },
  ];

  constructor() {
    makeAutoObservable(this);

    //check if logged in
    this.userData = JSON.parse(window.sessionStorage.getItem('userData'));

    const socket = io(this.baseURL);
    socket.on('request_update', data => {
      console.log('UPDATE!', data);
      if (this.userData) {
        if (this.autoRefresh) {
          this._refreshData();
        } else {
          console.log('Auto Refresh is Disabled');
        }
      }
    });

    if (this.userData) {
      this._refreshStatuses();
    }
  }

  _refreshData = () => {
    //update all necessary shit
    if (this.searchData.length > 0) {
      if (this.searchType === 'any') {
        this._searchAny({ query: this.searchQuery });
      } else {
        this._searchByName({ query: this.searchQuery });
      }
    }

    this._getRealTimeData();
  };

  _toggleAutoRefresh = value => {
    this.autoRefresh = value;
  };

  _updateStatusFilters = params => {
    this.statusFilters = params;
  };

  _globalToast = params => {
    params.duration = 9000;
    params.isClosable = true;
    params.position = 'bottom-right';

    const toast = createStandaloneToast();
    toast(params);

    // toast({
    //     title: "An error occurred.",
    //     description: "Unable to create user account.",
    //     status: "error",
    //     duration: 9000,
    //     isClosable: true,
    // })
  };

  _actionFunc = value => {
    this.variable = value;
  };

  _login = params => {
    return axios
      .post(`${this.baseURL}/auth/local`, {
        identifier: params.identifier,
        password: params.password,
      })
      .then(response => {
        // console.log('Login Success!', response);
        window.sessionStorage.setItem(
          'userData',
          JSON.stringify(response.data)
        );
        this.userData = response.data;
        this._globalToast({
          title: 'Login successful',
          status: 'success',
        });

        this._refreshStatuses();
      })
      .catch(err => {
        // console.log(err.response);

        const errMsg = err.response.data.message[0].messages[0].message;

        this._globalToast({
          title: 'Unable to login',
          status: 'error',
          description: errMsg,
        });
      });
  };

  _logout = cb => {
    this.userData = null;
    window.sessionStorage.removeItem('userData');
    cb();

    this._globalToast({
      title: 'You have been logged out.',
      status: 'success',
    });
  };

  _downloadCSV = async props => {
    if (props && props.startDate)
      props.startDate = moment(props.startDate).format('YYYY-MM-DD');
    if (props && props.startDate)
      props.endDate = moment(props.endDate).format('YYYY-MM-DD');

    var queryString = qs.stringify({
      _where: [
        { created_at_gte: props.startDate },
        {
          created_at_lte: moment(props.endDate)
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        },
      ],
    });

    return axios({
      method: 'get',
      url: `${this.baseURL}/requests/csv/0?${queryString}`,
      headers: {
        Authorization: `Bearer ${this.userData.jwt}`,
      },
      responseType: 'blob',
    })
      .then(res => {
        fileDownload(
          res.data,
          this.userData.user.username + '_kericity_' + new Date() + '.csv'
        );
      })
      .catch(err => {
        this._globalToast({
          title: 'Unable to download CSV',
          status: 'error',
          description: 'Please check your start and end dates',
        });
      });
  };

  _getRealTimeData = async props => {
    if (props && props.startDate)
      this.startDate = moment(props.startDate).format('YYYY-MM-DD');
    if (props && props.startDate)
      this.endDate = moment(props.endDate).format('YYYY-MM-DD');
    // if(props && props.endDate) this.endDate = moment(props.endDate[0]).add(1, "day").format("YYYY-MM-DD");

    // const startDate = moment().subtract(1, "week").format("YYYY-MM-DD");

    var queryString = qs.stringify({
      _where: [
        { created_at_gte: this.startDate },
        {
          created_at_lte: moment(this.endDate)
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        },
        {
          status: [
            ...this.statusFilters,
            ['In Transit to BPLO, In Transit to Client'],
          ],
        },
        // {status:[...this.statusFilters]},
      ],
    });

    this.loading = true;

    try {
      let data = await fetchData({
        url: `${this.baseURL}/requests?${queryString}`,
        total_url: `${this.baseURL}/requests/count?${queryString}`,
        token: `Bearer ${this.userData.jwt}`,
        onProgress: perc => {
          // if(props && props.setProgress) props.setProgress(Math.round(perc*100));
          runInAction(() => (this.realTimeProgress = Math.round(perc * 100)));
          console.log('Loaded:', Math.round(perc * 100), '%');
        },
        limit: pageLimit,
      });
      runInAction(() => {
        this.realtimeData = data;
        this.loading = false;
      });
    } catch (err) {
      this._globalToast({
        title: 'Unable to load data',
        status: 'error',
        description: 'Please check your internet connection',
      });
    }
  };

  _searchByName = async ({ query, setProgress }) => {
    if (query.length < 3) {
      alert('Please type in at least 3 characters');
      return;
    }

    this.searchQuery = query;
    this.searchType = 'name';

    let queryString;

    const fullName = query.split(' ');
    if (fullName.length === 1) {
      queryString = qs.stringify({
        _where: [
          {
            _or: [
              { first_name_contains: query },
              { last_name_contains: query },
            ],
          },
        ],
      });
    } else {
      const lastName = fullName.pop();
      const firstName = fullName.join(' ');
      queryString = qs.stringify({
        _where: [
          {
            _or: [
              { first_name_contains: firstName },
              { last_name_contains: lastName },
            ],
          },
        ],
      });
    }

    this.loading = true;

    try {
      let data = await fetchData({
        url: `${this.baseURL}/requests?${queryString}?`,
        total_url: `${this.baseURL}/requests/count?${queryString}`,
        token: `Bearer ${this.userData.jwt}`,
        onProgress: perc => {
          // if(setProgress) setProgress(Math.round(perc*100));
          runInAction(() => (this.searchProgress = Math.round(perc * 100)));
          console.log('Loaded:', Math.round(perc * 100), '%');
        },
        limit: pageLimit,
      });
      console.log(data);
      runInAction(() => {
        this.searchData = data;
        this.loading = false;
      });
    } catch (err) {
      console.log(err);
    }
  };

  _searchAny = async ({ query, setProgress }) => {
    if (query.length < 3) {
      alert('Please type in at least 3 characters');
      return;
    }

    this.searchQuery = query;
    this.searchType = 'any';

    this.loading = false;

    try {
      let data = await fetchData({
        url: `${this.baseURL}/requests?_q=${query}`,
        total_url: `${this.baseURL}/requests/count?_q=${query}`,
        token: `Bearer ${this.userData.jwt}`,
        onProgress: perc => {
          // if(setProgress) setProgress(Math.round(perc*100));
          runInAction(() => (this.searchProgress = Math.round(perc * 100)));
          // console.log("Loaded:", Math.round(perc*100), "%")
        },
        limit: pageLimit,
      });
      runInAction(() => {
        this.searchData = data;
        this.loading = true;
      });
    } catch (err) {
      console.log(err);
    }
  };

  _resetSearch = params => {
    this.searchQuery = '';
    this.searchData = [];
  };

  _updateStatus = params => {
    return axios({
      method: 'put',
      url: `${this.baseURL}/requests/updateStatus/${params.id}`,
      headers: {
        Authorization: `Bearer ${this.userData.jwt}`,
      },
      data: {
        status: params.status,
      },
    })
      .then(response => {
        // console.log("RES", response)
        this._globalToast({
          title: 'Status updated!',
          status: 'success',
        });
        this._refreshData();
      })
      .catch(err => {
        this._globalToast({
          title: 'Unable to status',
          status: 'error',
          descriptopm: 'Please check your internet connection',
        });
        console.log(err.response);
      });
  };

  _getHistory = params => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${this.baseURL}/requests/history/${params.tracking_id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          // console.log("RES", response)
          resolve(response);

          if (response.data.length > 0) {
            this._globalToast({
              title: 'History retrieved!',
              status: 'success',
            });
          } else {
            this._globalToast({
              title: 'History unavailable',
              status: 'error',
            });
          }
        })
        .catch(err => {
          reject(err);
          this._globalToast({
            title: 'Unable to retrieve history',
            status: 'error',
            descriptopm: 'Please check your internet connection',
          });
          console.log(err.response);
        });
    });
  };

  _getProof = params => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${this.baseURL}/requests/proof/${params.id}`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          console.log('RES', response);
          resolve(response);
          this._globalToast({
            title: 'Proof retrieved!',
            status: 'success',
          });
        })
        .catch(err => {
          reject(err);
          this._globalToast({
            title: 'Unable to retrieve proof',
            status: 'error',
            descriptopm: 'Please check your internet connection',
          });
          console.log(err.response);
        });
    });
  };

  _refreshStatuses = params => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${this.baseURL}/requests/checkStatuses/0`,
        headers: {
          Authorization: `Bearer ${this.userData.jwt}`,
        },
      })
        .then(response => {
          // console.log("RES", response)

          let sum = Object.values(response.data).reduce(function (a, b) {
            return a + (b < 0 ? 0 : b);
          }, 0);

          const msg = `${sum} requests updated as of ${moment(new Date())
            .local()
            .format('YYYY-DD-MM LT')}`;

          this._globalToast({
            title: 'Statuses have been refreshed',
            status: 'success',
            description: msg,
          });

          resolve(response);
        })
        .catch(err => {
          reject(err);
          this._globalToast({
            title: 'Unable to refresh statuses',
            status: 'error',
            description: 'Please check your internet connection',
          });
          console.log(err.response);
        });
    });
  };

  _checkPackID = async value => {
    await new Promise(resolve => setTimeout(resolve, 2000));

    return new Promise((resolve, reject) => {
      axios
        .get(`${this.baseURL}/packs?pack_id=${value}`)
        .then(response => {
          console.log('RES', response);
          if (response.data.length > 0) {
            if (!response.data[0].used) {
              return resolve(true);
            }
          }
          reject();
        })
        .catch(err => {
          console.log(err.response);
          reject();
        });
    });
  };

  _createOrder = params => {
    return axios({
      method: 'post',
      url: `${this.baseURL}/orders`,
      headers: {
        Authorization: `Bearer ${this.userData.data.jwt}`,
      },
      data: {
        order: params.puDetails,
        parcels: params.orderDetails,
      },
    })
      .then(response => {
        console.log('RES', response);
      })
      .catch(err => {
        console.log(err.response);
      });
  };
}

export default createContext(new MainStoreContext());
